import { isOutOfBoundsInt, isTooLongStringInput, isValidUrl } from '../../../../../../common/validations';
import isEmptyString from '../../../../../../common/is-empty-string';
import { DetailsFormProblem } from '../../SubmitGame/submission-errors';
import {
  buildEmptyGameCovers,
  buildEmptySubmissionVideo,
  GAME_COVERS_RESOLUTIONS,
  GameCoversWithUploadedFile,
  SubmissionVideo,
} from '../../../../../../common/graphql/games/game';
import { VideoUploadProgress } from '../../../Admin/Game/VideoUpload';
import { Submission } from '../submission-graphql';

type VideoUploadProgressData = { [k: string]: VideoUploadProgress }; // stores the video key and its progress

export interface DetailsFormData {
  category: string | null;
  tags: string[];
  description: string | null;
  controls: string | null;
  playUrl: string | null;
  appstoreUrl: string | null;
  steamUrl: string | null;
  playStoreDownloads: string | null; // will be converted to number before sending to server
  appStoreDownloads: string | null; // will be converted to number before sending to server
  steamDownloads: string | null; // will be converted to number before sending to server
  gameCovers: GameCoversWithUploadedFile;
  videos: SubmissionVideo;
  videoUploadProgress: VideoUploadProgressData;
}

export const MAX_DESCRIPTION_LENGTH = 6000;
export const MAX_CONTROLS_LENGTH = 3500;

export function buildDefaultDetailsFormData(): DetailsFormData {
  return {
    category: null,
    tags: [],
    description: null,
    controls: null,
    playUrl: null,
    appstoreUrl: null,
    steamUrl: null,
    playStoreDownloads: null,
    appStoreDownloads: null,
    steamDownloads: null,
    gameCovers: buildEmptyGameCovers(),
    videos: buildEmptySubmissionVideo(),
    videoUploadProgress: {},
  };
}

export function validateDetailsForm(submission: Submission | null, form: DetailsFormData): DetailsFormProblem[] {
  const problems: DetailsFormProblem[] = [
    ...validateCategory(form.category),
    ...validateTags(form.tags),
    ...validateDescription(form.description),
    ...validateControls(form.controls),
    ...validatePlayUrl(form.playUrl),
    ...validateSteamUrl(form.steamUrl),
    ...validateAppstoreUrl(form.appstoreUrl),
    ...validatePlayStoreDownloads(form.playStoreDownloads),
    ...validateAppStoreDownloads(form.appStoreDownloads),
    ...validateSteamDownloads(form.steamDownloads),
    ...validateCoverImages(form.gameCovers),
    ...validateVideos(submission, form),
  ];
  return problems;
}

export function validateCategory(category: string | null): DetailsFormProblem[] {
  if (isEmptyString(category)) {
    return ['CATEGORY_MISSING'];
  }
  return [];
}

export function validateTags(tags: string[]): DetailsFormProblem[] {
  if (!tags || tags.length < 1) {
    return ['TAG_MISSING'];
  }
  return [];
}

export function validateDescription(description: string | null): DetailsFormProblem[] {
  if (isEmptyString(description)) {
    return ['DESCRIPTION_MISSING'];
  }
  if (description!.length > MAX_DESCRIPTION_LENGTH) {
    return ['DESCRIPTION_TOO_LONG'];
  }
  return [];
}

export function validateControls(controls: string | null): DetailsFormProblem[] {
  if (isEmptyString(controls)) {
    return ['CONTROLS_MISSING'];
  }
  if (controls!.length > MAX_CONTROLS_LENGTH) {
    return ['CONTROLS_TOO_LONG'];
  }
  return [];
}

export function validatePlayUrl(playUrl: string | null): DetailsFormProblem[] {
  if (!isEmptyString(playUrl) && (isTooLongStringInput(playUrl!) || !isValidUrl(playUrl as string))) {
    return ['PLAY_URL_INVALID'];
  }
  return [];
}

export function validateAppstoreUrl(appstoreUrl: string | null): DetailsFormProblem[] {
  if (!isEmptyString(appstoreUrl) && (isTooLongStringInput(appstoreUrl!) || !isValidUrl(appstoreUrl as string))) {
    return ['APPSTORE_URL_INVALID'];
  }
  return [];
}

export function validateAppStoreDownloads(downloads: string | null): DetailsFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['APP_STORE_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validatePlayStoreDownloads(downloads: string | null): DetailsFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['PLAY_STORE_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validateSteamDownloads(downloads: string | null): DetailsFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['STEAM_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validateSteamUrl(steamUrl: string | null): DetailsFormProblem[] {
  if (!isEmptyString(steamUrl) && (isTooLongStringInput(steamUrl!) || !isValidUrl(steamUrl as string))) {
    return ['STEAM_URL_INVALID'];
  }
  return [];
}

function validateCoverImages(coverImages: GameCoversWithUploadedFile | null): DetailsFormProblem[] {
  const problems: DetailsFormProblem[] = [];
  if (coverImages === null) {
    problems.push('COVER_IMAGES_MISSING');
  } else {
    for (const coverId in coverImages) {
      if (!coverImages[coverId as GAME_COVERS_RESOLUTIONS]) {
        problems.push(`COVER_IMAGE_MISSING${coverId}` as DetailsFormProblem);
      }
    }
  }
  return problems;
}

function validateVideos(submission: Submission | null, form: DetailsFormData): DetailsFormProblem[] {
  const { videos, videoUploadProgress } = form;
  const errors: DetailsFormProblem[] = [];

  const totalEntries = Object.keys(videoUploadProgress).length;
  const notStartedEntries = Object.values(videoUploadProgress).filter((progress) => progress === 'not-started').length;

  if (totalEntries !== notStartedEntries) {
    errors.push('VIDEO_UPLOADING');
  }

  // specific fix for some devs with draft submissions with no videos that currently require QA check
  // this means that they currently see the first page, where they cannot upload videos
  // temporary, shapeup will change submission process
  // won't cause issues on 2nd page, as at that point QA tool is not required
  if (submission && submission.status === 'DRAFT' && submission.requiresDevQaCheck) {
    return errors;
  }

  if (!submission || submission.status === 'DRAFT') {
    if (!videos.videoThumbnail) {
      errors.push('LANDSCAPE_VIDEO_MISSING');
    }
  }

  return errors;
}
