import React from 'react';
import { Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material';
import CategorySelector from './CategorySelector';
import TagsSelector from './TagsSelector';
import { GameTag, GameCategory } from './gamedetailsform-graphql';
import { MAX_NO_TAGS } from '../GameBaseForm/baseFormHelper';
import { classes } from '../styledSubmission';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import withTheme, { WithTheme } from '../../../../../helpers/WithTheme';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { Add, Help, Remove } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import withSubmission, { WithSubmission } from '../Context/withSubmission';
import { valueFromInput } from '../../../../../../common/input';
import { MAX_CONTROLS_LENGTH, MAX_DESCRIPTION_LENGTH } from './detailsFormHelper';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import SubmissionUpdateGameCovers from '../../../Admin/Submission/UpdateSubmission/SubmissionUpdateGameCovers';
import { GAME_COVERS_RESOLUTIONS, GameCoversWithUploadedFile } from '../../../../../../common/graphql/games/game';
import { UploadedFile } from '../../../../../../common/domain/upload';
import OrientationSelector, { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import MultiplayerLobbyEditor from '../GameBaseForm/MultiplayerLobbyEditor';
import VideoUpload from '../../../Admin/Game/VideoUpload';

export interface Props {
  tags: GameTag[];
  isReleased?: boolean;
  categories: GameCategory[];
  attemptedToSave?: boolean;
}

type _Props = Props & WithTheme & WithSubmission;

interface State {
  isCollapsed: boolean;
}

/**
 * Renders the info form fields (game name, category, tags, description, controls, etc).
 * Updates this data, and uses it directly from Submission context.
 */
class GameDetailsForm extends React.Component<_Props, State> {
  constructor(props: _Props) {
    super(props);
    this.state = {
      isCollapsed: !!props.isReleased,
    };
  }

  render() {
    const { isReleased } = this.props;
    const { isCollapsed } = this.state;

    return (
      <>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            p: 2,
            px: 3,
          }}
        >
          {isReleased ? (
            this.renderReleasedGameHeader()
          ) : (
            <StyledHeaderText
              variant="h2"
              sx={{
                pb: 1,
                m: 0,
              }}
            >
              Game details
            </StyledHeaderText>
          )}
          {!isCollapsed && (
            <>
              {this.renderCategory()}
              {this.renderDescription()}
              {this.renderOtherPlatforms()}
            </>
          )}
        </StyledContainer>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            p: 2,
            px: 3,
          }}
        >
          <StyledHeaderText
            variant="h2"
            sx={{
              pb: 1,
              m: 0,
            }}
          >
            Cover images & videos
          </StyledHeaderText>
          {this.renderCoverImageUpload()}
          {this.renderVideoUpload()}
        </StyledContainer>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            p: 2,
            px: 3,
          }}
        >
          <StyledHeaderText
            variant="h2"
            sx={{
              pb: 1,
              m: 0,
            }}
          >
            Display options
          </StyledHeaderText>
          {this.renderDisplayOptions()}
        </StyledContainer>
      </>
    );
  }

  private renderReleasedGameHeader() {
    const { isCollapsed } = this.state;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledHeaderText
          variant="h2"
          sx={{
            m: 0,
          }}
        >
          Game details
        </StyledHeaderText>
        <StyledBodyText sx={{ m: 0.5, flex: 1, ml: 4 }} variant="bodyLower2" color="white80">
          You can't update this section. Feel free to <Link to="/support">contact us</Link> if you have any question.
        </StyledBodyText>
        {isCollapsed ? (
          <Add
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: false,
              })
            }
          />
        ) : (
          <Remove
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: true,
              })
            }
          />
        )}
      </div>
    );
  }

  private renderOtherPlatforms() {
    const { playUrl, appstoreUrl, steamUrl, playStoreDownloads, appStoreDownloads, steamDownloads } = this.props.detailsForm;
    const { isReleased, detailsFormProblems } = this.props;
    const invalidPlayUrl = detailsFormProblems?.includes('PLAY_URL_INVALID');
    const invalidStoreUrl = detailsFormProblems?.includes('APPSTORE_URL_INVALID');
    const invalidSteamUrl = detailsFormProblems?.includes('STEAM_URL_INVALID');
    const invalidPlayStoreDownloads = detailsFormProblems?.includes('PLAY_STORE_DOWNLOADS_INVALID');
    const invalidAppStoreDownloads = detailsFormProblems?.includes('APP_STORE_DOWNLOADS_INVALID');
    const invalidSteamDownloads = detailsFormProblems?.includes('STEAM_DOWNLOADS_INVALID');

    return (
      <Grid container direction="row" spacing={2}>
        <Grid container item className={`${classes.leftColumn} ${classes.thirdColumn}`} xs={12} md={4} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
              Google Play Store link
            </StyledBodyText>
            <StyledInput
              type="url"
              defaultValue={playUrl}
              error={invalidPlayUrl}
              disabled={isReleased}
              required={false}
              onChange={this.handlePlayUrlChange}
            />
            {invalidPlayUrl && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid Google Play Store link`}
              </StyledBodyText>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25, display: 'flex' }} color="white80">
              Approximate # downloads
              <Tooltip title={'Games with a high number of downloads are eligible for custom contractual terms'}>
                <Help sx={{ width: 20, ml: 0.5 }} />
              </Tooltip>
            </StyledBodyText>
            <StyledInput
              defaultValue={playStoreDownloads}
              error={invalidPlayStoreDownloads}
              disabled={isReleased}
              required={false}
              onChange={this.handlePlayStoreDownloadsChange}
            />
            {invalidPlayStoreDownloads && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid downloads number`}
              </StyledBodyText>
            )}
          </Grid>
        </Grid>
        <Grid container item className={`${classes.leftColumn} ${classes.thirdColumn}`} xs={12} md={4} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
              iOS App Store link
            </StyledBodyText>
            <StyledInput
              type="url"
              defaultValue={appstoreUrl}
              error={invalidStoreUrl}
              disabled={isReleased}
              required={false}
              onChange={this.handleAppstoreUrlChange}
            />
            {invalidStoreUrl && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid IOS App Store link`}
              </StyledBodyText>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25, display: 'flex' }} color="white80">
              Approximate # downloads
              <Tooltip title={'Games with a high number of downloads are eligible for custom contractual terms'}>
                <Help sx={{ width: 20, ml: 0.5 }} />
              </Tooltip>
            </StyledBodyText>
            <StyledInput
              defaultValue={appStoreDownloads}
              error={invalidAppStoreDownloads}
              disabled={isReleased}
              required={false}
              onChange={this.handleAppStoreDownloadsChange}
            />
            {invalidAppStoreDownloads && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid downloads number`}
              </StyledBodyText>
            )}
          </Grid>
        </Grid>
        <Grid item className={`${classes.columnForm} ${classes.thirdColumn}`} xs={12} md={4} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
              Steam link
            </StyledBodyText>
            <StyledInput
              type="url"
              defaultValue={steamUrl}
              error={invalidSteamUrl}
              disabled={isReleased}
              required={false}
              onChange={this.handleSteamUrlChange}
            />
            {invalidSteamUrl && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid Steam link`}
              </StyledBodyText>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25, display: 'flex' }} color="white80">
              Approximate # downloads
              <Tooltip title={'Games with a high number of downloads are eligible for custom contractual terms'}>
                <Help sx={{ width: 20, ml: 0.5 }} />
              </Tooltip>
            </StyledBodyText>
            <StyledInput
              defaultValue={steamDownloads}
              error={invalidSteamDownloads}
              disabled={isReleased}
              required={false}
              onChange={this.handleSteamDownloadsChange}
            />
            {invalidSteamDownloads && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid downloads number`}
              </StyledBodyText>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private handlePlayUrlChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const playUrl = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, playUrl });
  };

  private handlePlayStoreDownloadsChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const playStoreDownloads = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, playStoreDownloads });
  };

  private handleAppstoreUrlChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const appstoreUrl = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, appstoreUrl });
  };

  private handleAppStoreDownloadsChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const appStoreDownloads = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, appStoreDownloads });
  };

  private handleSteamDownloadsChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const steamDownloads = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, steamDownloads });
  };

  private handleSteamUrlChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const steamUrl = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, steamUrl });
  };

  private renderCategory() {
    const { tags, categories, isReleased, attemptedToSave, detailsFormProblems } = this.props;
    const { category: selectedCategory, tags: selectedTags } = this.props.detailsForm;
    const categoryError = attemptedToSave && detailsFormProblems?.includes('CATEGORY_MISSING');
    const tagError = attemptedToSave && detailsFormProblems?.includes('TAG_MISSING');
    return (
      <>
        <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1 }} color="white80">
          Category <span>*</span>
        </StyledBodyText>
        <CategorySelector
          disabled={isReleased}
          error={categoryError}
          categories={categories}
          selected={selectedCategory}
          onChange={this.handleCategoryChange}
        />
        {categoryError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select a category`}
          </StyledBodyText>
        )}

        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          Tags <span>*</span>{' '}
          <span style={{ fontSize: 12, color: COLORS.white[50], fontWeight: 400, marginLeft: 8 }}>MAX. {MAX_NO_TAGS}</span>
        </StyledBodyText>
        <TagsSelector error={tagError} tags={tags} selected={selectedTags} onChange={this.handleTagsChange} disabled={isReleased} />
        {tagError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select at least 1 tag`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleCategoryChange = (category: string | null) => {
    this.props.updateDetailsForm({ ...this.props.detailsForm, category });
  };

  private handleTagsChange = (tags: string[]) => {
    this.props.updateDetailsForm({ ...this.props.detailsForm, tags });
  };

  private renderDescription() {
    const { description, controls } = this.props.detailsForm;
    const { isReleased, theme, attemptedToSave, detailsFormProblems } = this.props;

    const descriptionMissing = attemptedToSave && detailsFormProblems?.includes('DESCRIPTION_MISSING');
    const descriptionTooLong = detailsFormProblems?.includes('DESCRIPTION_TOO_LONG');
    const controlMissing = attemptedToSave && detailsFormProblems?.includes('CONTROLS_MISSING');
    const controlTooLong = detailsFormProblems?.includes('CONTROLS_TOO_LONG');

    const descriptionError = descriptionMissing || descriptionTooLong;
    const controlError = controlMissing || controlTooLong;

    return (
      <div style={{ marginTop: theme.spacing(3) }}>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
          Description <span>*</span>{' '}
          <span style={{ fontSize: 12, color: COLORS.white[50], fontWeight: 400, marginLeft: 8 }}>NO HTML ALLOWED</span>
        </StyledBodyText>
        <StyledInput
          value={description || ''}
          error={descriptionError}
          disabled={isReleased}
          required={true}
          multiline={true}
          onChange={this.handleDescriptionChange}
        />
        {descriptionMissing && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please fill in the input `}
          </StyledBodyText>
        )}
        {descriptionTooLong && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please limit your input to ${MAX_DESCRIPTION_LENGTH} characters`}
          </StyledBodyText>
        )}
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          Controls <span>*</span>
        </StyledBodyText>
        <StyledInput
          error={controlError}
          value={controls || ''}
          disabled={isReleased}
          required={true}
          multiline={true}
          onChange={this.handleControlsChange}
        />
        {controlMissing && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please fill in the input `}
          </StyledBodyText>
        )}
        {controlTooLong && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please limit your input to ${MAX_CONTROLS_LENGTH} characters`}
          </StyledBodyText>
        )}
      </div>
    );
  }

  private renderCoverImageUpload() {
    const { detailsFormProblems, attemptedToSave, isNonEditable } = this.props;
    const coverError16x9 = attemptedToSave && detailsFormProblems?.includes('COVER_IMAGE_MISSING16x9');
    const coverError2x3 = attemptedToSave && detailsFormProblems?.includes('COVER_IMAGE_MISSING2x3');
    const coverError1x1 = attemptedToSave && detailsFormProblems?.includes('COVER_IMAGE_MISSING1x1');
    const anyCoverError = coverError16x9 || coverError1x1 || coverError2x3;

    const errors = {
      '16x9': !!coverError16x9,
      '2x3': !!coverError2x3,
      '1x1': !!coverError1x1,
    };

    return (
      <>
        <StyledBodyText variant="bodyLower" color="white30" sx={{ mb: 3 }}>
          We will use the cover image to show your game on our pages (homepage, category pages, …). Make it appealing and professional
          looking! A good cover image will make the users want to play your game. Please provide 3 sizes for your game cover: landscape
          (1920x1080) portrait (800x1200) and square (800x800). For more information, make sure to read our{` `}
          <a href={`${DOCS_URL}/requirements/game-covers/`} target="_blank" rel="noreferrer">
            guidelines for game covers
          </a>
          .
        </StyledBodyText>

        <SubmissionUpdateGameCovers
          gameCovers={this.props.detailsForm.gameCovers}
          errors={errors}
          onChange={this.handleCoverImageChanged}
          isNonEditable={isNonEditable}
        />
        {anyCoverError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please upload a cover image for each aspect ratio`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private renderVideoUpload() {
    if (!this.props.submission) {
      return;
    }
    const { portraitVideoOriginal, videoOriginal } = this.props.detailsForm.videos;
    const { slug } = this.props.submission;
    const { updateDetailsForm, setVideoUploadProgress, detailsForm, detailsFormProblems, attemptedToSave } = this.props;

    const videoStillUploadingError = detailsFormProblems?.includes('VIDEO_UPLOADING');
    const landscapeVideoMissing = detailsFormProblems?.includes('LANDSCAPE_VIDEO_MISSING');

    return (
      <>
        <StyledBodyText variant="bodyLower" color="white30" sx={{ mb: 3 }}>
          Game videos will be displayed when players hover over your game cover. We reserve the right to replace them with our own. For more
          information, make sure to read our{` `}
          <a href={`${DOCS_URL}/requirements/game-covers/#preview-video`} target="_blank" rel="noreferrer">
            guidelines for game videos
          </a>
          .
        </StyledBodyText>{' '}
        <StyledBodyText variant="bodyLower" color="white30" sx={{ mb: 3 }}>
          Supported formats: MP4, MOV. Max length 20 seconds.
        </StyledBodyText>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
          <VideoUpload
            originalVideo={videoOriginal}
            slug={slug}
            onUploadStatusChange={(status) => {
              setVideoUploadProgress('landscape', status);
            }}
            error={landscapeVideoMissing && attemptedToSave ? 'Please upload a landscape video' : ''}
            mandatory={true}
            onChange={(thumbnailLocation, originalLocation) => {
              updateDetailsForm({
                ...detailsForm,
                videos: {
                  ...detailsForm.videos,
                  videoOriginal: originalLocation,
                  videoThumbnail: thumbnailLocation,
                },
              });
            }}
            label={'Landscape video *'}
            videoContainerRatio={{ width: 180, height: 112 }}
          />
          <VideoUpload
            originalVideo={portraitVideoOriginal}
            slug={slug}
            onUploadStatusChange={(status) => {
              setVideoUploadProgress('portrait', status);
            }}
            onChange={(thumbnailLocation, originalLocation) => {
              this.props.updateDetailsForm({
                ...detailsForm,
                videos: {
                  ...detailsForm.videos,
                  portraitVideoOriginal: originalLocation,
                  portraitVideoThumbnail: thumbnailLocation,
                },
              });
            }}
            label={'Portrait video'}
            videoContainerRatio={{ width: 112, height: 180 }}
          />
        </div>
        {videoStillUploadingError && attemptedToSave && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="error">
            Please wait for video upload to complete
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleCoverImageChanged = (coverId: GAME_COVERS_RESOLUTIONS, file: UploadedFile | null) => {
    const currentForm = this.props.detailsForm;
    this.props.updateDetailsForm({
      ...currentForm,
      gameCovers: { ...currentForm.gameCovers, [coverId]: file } as GameCoversWithUploadedFile,
    });
  };

  private handleDescriptionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const description = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, description });
  };

  private handleControlsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const controls = valueFromInput(evt);
    this.props.updateDetailsForm({ ...this.props.detailsForm, controls });
  };

  private renderMobileOrientation() {
    const { isIOSFriendly, isAndroidFriendly, orientation } = this.props.baseForm;
    const { baseFormProblems, attemptedToSave, isNonEditable } = this.props;
    const orientationError = attemptedToSave && baseFormProblems?.includes('ORIENTATION_MISSING');

    return (
      (isIOSFriendly || isAndroidFriendly) && (
        <>
          <StyledBodyText variant="h3" sx={{ mt: 1.25, mb: 1.25 }}>
            Screen orientation on mobile <span>*</span>
          </StyledBodyText>
          <OrientationSelector
            disabled={isNonEditable}
            error={orientationError}
            defaultValue={orientation}
            onChange={this.handleOrientationChange}
          />
          {orientationError && (
            <StyledBodyText sx={{ m: 0.5, height: 20 }} variant="bodyLower2" color="error">
              Please select a orientation
            </StyledBodyText>
          )}
          <StyledBodyText variant="bodyLower" sx={{ marginBlock: 0 }} color="white30">
            Note: the orientation locking will be managed by us.
          </StyledBodyText>
        </>
      )
    );
  }

  private handleOrientationChange = (orientation: string | null) => {
    this.props.updateBaseForm({ ...this.props.baseForm, orientation: orientation as GAME_ORIENTATION | null });
  };

  private renderFullscreen() {
    // To keep things simply the dev is presented just with a "Supports fullscreen" checkbox. However, on the backend this is translated to multiple options.
    const { fullscreen } = this.props.baseForm;
    return (
      <>
        <FormControlLabel
          control={<Checkbox checked={fullscreen !== 'NOT_SUPPORTED'} onChange={this.handleIsFullscreenableChange} />}
          label="The game works well in fullscreen"
          sx={{ color: COLORS.black[10] }}
        />
      </>
    );
  }

  private handleIsFullscreenableChange = (_evt: {}, isFullscreenable: boolean) => {
    this.props.updateBaseForm({ ...this.props.baseForm, fullscreen: isFullscreenable ? 'SUPPORTED' : 'NOT_SUPPORTED' });
  };

  private renderDisplayOptions() {
    // on backend, when you submit a new submission and are completing the 2nd step, these flags from here will be copied directly in the latest build to avoid creating a new build that would require one more QA check
    return (
      <>
        {this.renderFullscreen()}
        {this.renderMobileOrientation()}
        <MultiplayerLobbyEditor />
      </>
    );
  }
}

export default withTheme(withSubmission(GameDetailsForm));
